import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import BookingIcon from "../../../component/menuicons/BookingsIcon";
import DashboardIcon from "../../../component/menuicons/DashboardIcon";
import Explore from "../../../component/menuicons/Explore";
import MessageIcon from "../../../component/menuicons/MessageIcon";
import NotificationIcon from "../../../component/menuicons/NotificationIcon";
import ProfileIcon from "../../../component/menuicons/ProfileIcon";
import SwitchIcon from "../../../component/menuicons/SwitchIcon";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Footer from "./Footer";
import event2tlogo from "../../../assets/images/svg/event2tlogo.svg";
import { getNotificationCountApi } from "../../../api/notifications";
import { unRedMessageApi } from "../../../api/messages";
import { clearBreadCrum } from "../../../store/slices/breadcrumb";
import { Container } from "@mui/system";
import {
  setFilteredInfo,
  setStartTime,
  setEndTime,
  setDays,
  setSelectedService,
  showModal,
} from "../../../store/slices/common";
import { getProfileApi, callSwitchProfileApi } from "../../../api/profile";
import { setProfileInfo, setSwitchRole } from "../../../store/slices/profileinfo";
import { handleProfileInfo } from "../../../utils/handleProfileInfo";
import { modalVariant } from "../../../component/Modal/Modalutils";

const drawerWidth = 170;

const navItems = [
  { icon: Explore, heading: "Explore", url: "/" },
  {
    icon: DashboardIcon,
    heading: "Dashboard",
    url: "/event-dashboard",
  },
  { icon: MessageIcon, heading: "Message", url: "/messages" },
  {
    icon: NotificationIcon,
    heading: "Notification",
    url: "/notifications",
  },
  {
    icon: SwitchIcon,
    heading: localStorage.getItem("role") == "1" ? "Switch to Event Creator" : "Switch to Eventutti Partner",
  },
  { icon: ProfileIcon, heading: "Profile", url: "/settings" },
];

const navItemsServ = [
  {
    icon: DashboardIcon,
    heading: "Dashboard",
    url: "/serviceprovider-dashboard",
  },
  { icon: BookingIcon, heading: "My Booking", url: "/booking-info" },
  {
    icon: Explore,
    heading: "My Services",
    url: "/myservices",
  },
  { icon: MessageIcon, heading: "Message", url: "/messages" },
  {
    icon: NotificationIcon,
    heading: "Notification",
    url: "/notifications",
  },
  {
    icon: SwitchIcon,
    heading: localStorage.getItem("role") == "1" ? "Switch to Eventutti Partner" : "Switch to Event Creator",
  },

  { icon: ProfileIcon, heading: "Profile", url: "/settings" },
];

function NavBar({ children }, props) {
  const { window } = props;
  const role = localStorage.getItem("role") || 0;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [selected, setSelected] = useState();
  const [switchButtonDisabled, setSwitchButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const itemsToMap = role === "1" ? navItemsServ : navItems;
  const location = useLocation();
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const callApi = async () => {
    if (token) {
      const response = await getNotificationCountApi();
      const resp = await unRedMessageApi();
      if (response?.status === 200) setNotificationCount(response.data.data.count);
      if (resp?.status == 200) setMessageCount(resp.data.data.unreadCount);
    }
  };

  const handleLogoNavigation = async () => {
    if (role == "1") {
      navigate("/serviceprovider-dashboard");
    } else {
      if (location.pathname == "/") {
        dispatch(
          setSelectedService({
            displayImage: {
              fileUrl: "https://d2jk0v9jizbe36.cloudfront.net/file_1712242067844.jpg",
              key: "",
            },
            _id: "65fd45565dfb510fd7adf259",
            name: "Venues",
            createdAt: "2024-03-22T08:46:14.382Z",
            updatedAt: "2024-04-04T14:48:02.291Z",
            __v: 0,
          })
        );
        dispatch(
          setFilteredInfo({
            guestCount: "",
            city: "",
            category: "",
            budget: [0, 10000],
          })
        );
        dispatch(setStartTime(null));
        dispatch(setEndTime(null));
        dispatch(setDays([]));
      } else navigate("/");
    }
  };

  const handleGetProfileInfo = async (roleinfo, info) => {
    const resp = await getProfileApi();
    if (resp?.status == 200) {
      let role = roleinfo == "sp" ? 1 : 0;
      handleProfileInfo(resp?.data?.data, dispatch, setProfileInfo, role);
    }
    if (roleinfo == "sp") {
      if (!(info?.isDocumentUpdated && info?.isQuestionsUpdated)) {
        navigate("/switch-profile");
        localStorage.setItem("role", 1);
      } else if (info?.status == "Rejected") {
        dispatch(
          showModal({
            modalType: modalVariant.REQUEST_REJECT,
            resolveCallback: () => {
              handleRejectScreen();
            },
          })
        );
        const resp = await callSwitchProfileApi();
        if (resp?.status == 200) {
          dispatch(setSwitchRole(0));
          localStorage.setItem("role", 0);
          localStorage.setItem("token", resp.data.data.token);
          dispatch(setProfileInfo({ screen: 1, userId: resp?.data?.data?.userId }));
        }
      } else if (info?.status == "Pending" && !info?.isBankingDetailsUpdated) {
        navigate("/profilecreationsucessful?id=2");
        localStorage.setItem("role", 1);
      } else if (!info?.isBankingDetailsUpdated) {
        localStorage.setItem("role", 1);
        navigate("/profilecreationsucessful?id=3");
      } else if (info?.status == "Approved" && info?.isBankingDetailsUpdated) {
        navigate("/myservices");
        localStorage.setItem("role", 1);
      }
    }
  };

  const handleRejectScreen = async () => {
    dispatch(showModal(null));
  };

  const handleSwitchProfile = async () => {
    setSwitchButtonDisabled(true);
    const resp = await callSwitchProfileApi();
    if (resp?.status == 200) {
      setTimeout(() => {
        setSwitchButtonDisabled(false);
      }, 500);
      if (role == "0") {
        dispatch(setSwitchRole(1));
        localStorage.setItem("token", resp.data.data.token);
        handleGetProfileInfo("sp", resp?.data?.data);
      } else {
        dispatch(setSwitchRole(0));
        localStorage.setItem("role", 0);
        localStorage.setItem("token", resp.data.data.token);
        dispatch(setProfileInfo({ screen: 1, userId: resp?.data?.data?.userId }));
        navigate("/");
      }
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{ m: 2, width: "140px" }}
        onClick={() => {
          handleLogoNavigation();
        }}
      >
        <img alt="" src={event2tlogo} style={{ width: "100%", height: "100%" }} />
      </Box>
      <Divider />
      <List>
        {itemsToMap?.map((item) => (
          <ListItem
            key={item}
            disablePadding
            onClick={() => {
              handleMenuClick(item);
            }}
          >
            <ListItemButton sx={{ textAlign: "flex-start", fontFamily: "Montserrat, sans-serif" }}>
              <ListItemText
                sx={{
                  display: "block",
                  color: item.heading == selected ? "#fff" : "black",
                  background:
                    selected == item?.heading ? "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100%)" : "transparent",
                  padding: "10px",
                  borderRadius: "15px",
                  position: "relative",
                  fontFamily: "Montserrat, sans-serif !important",
                }}
              >
                {item.heading == "Notification" && notificationCount > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "red",
                      color: "#fff",
                      borderRadius: "50%",
                      width: "7px",
                      height: "7px",
                      fontSize: "10px",
                      right: "20px",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  ></Box>
                )}
                {item.heading == "Message" && messageCount > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      backgroundColor: "red",
                      color: "#fff",
                      borderRadius: "50%",
                      width: "7px",
                      height: "7px",
                      fontSize: "10px",
                      right: "40px",
                      fontFamily: "Montserrat, sans-serif",
                      top: "10px",
                    }}
                  ></Box>
                )}
                {item.heading}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleMenuClick = (item) => {
    if (!token) {
      navigate("/login");
    } else {
      dispatch(clearBreadCrum());
      if (item.heading == "Switch to Eventutti Partner" || item.heading == "Switch to Event Creator")
        handleSwitchProfile();
      else if (token) navigate(item.url);
    }
  };
  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    if (
      location.pathname == "/" ||
      (location.pathname.includes("service-info") && role == "0") ||
      location.pathname.includes("booking-confirmation")
    )
      setSelected("Explore");
    else if (location.pathname == "/myservices" || (location.pathname.includes("service-info") && role == "1"))
      setSelected("My Services");
    else if (location.pathname == "/booking-info" || (location.pathname.includes("request-info") && role == "1"))
      setSelected("My Booking");
    else if (location.pathname == "/settings") setSelected("Profile");
    else if (
      location.pathname == "/event-dashboard" ||
      location.pathname == "/serviceprovider-dashboard" ||
      location.pathname == "/ratings-and-reviews" ||
      (location.pathname.includes("request-info") && role == "0")
    )
      setSelected("Dashboard");
    else if (location.pathname == "/messages") setSelected("Message");
    else if (location.pathname == "/notifications") setSelected("Notification");
  }, [location.pathname]);

  useEffect(() => {
    if (token) callApi();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", background: "#fff" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            background: "white",
            boxShadow: "0 5px 5px rgba(23, 186, 174, 0.1)",
          }}
          className="toolbarnav"
        >
          <Container
            sx={{
              padding: {
                xs: "0 15px",
                sm: "0 30px",
                md: "0 30px",
                lg: "0 30px",
                xl: 0,
              },
            }}
            maxWidth="xl"
          >
            <Toolbar
              sx={{
                padding: {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                  xl: 0,
                },
              }}
              className="toolbarnav"
            >
              <IconButton
                color="#fff"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" }, height: "100%" }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: { xs: "none", sm: "block", cursor: "pointer" },
                    width: { sm: "152px", md: "192px", xl: "242px" },
                  }}
                  onClick={() => {
                    handleLogoNavigation();
                  }}
                >
                  <img alt="" src={event2tlogo} style={{ width: "100%", height: "100%" }} />
                </Box>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {itemsToMap?.map((item, index) => (
                  <Button
                    key={item}
                    sx={{ color: "#fff", textTransform: "capitalize" }}
                    onClick={() => {
                      if (index !== itemsToMap.length - 2 || !switchButtonDisabled) handleMenuClick(item);
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "block", position: "relative" }}>
                        <>
                          {item.heading == "Notification" && notificationCount > 0 && (
                            <Box
                              sx={{
                                position: "absolute",
                                backgroundColor: "red",
                                color: "#fff",
                                borderRadius: "50%",
                                width: "7px",
                                height: "7px",
                                fontSize: "10px",
                                right: {
                                  xs: "35px",
                                  sm: "20px",
                                  md: "25px",
                                  lg: "35px",
                                  xl: "35px",
                                },
                                top: "-2px",
                                fontFamily: "Montserrat, sans-serif",
                              }}
                              className="notifcationred"
                            ></Box>
                          )}
                          {item.heading == "Message" && messageCount > 0 && (
                            <Box
                              sx={{
                                position: "absolute",
                                backgroundColor: "red",
                                color: "#fff",
                                borderRadius: "50%",
                                width: "7px",
                                height: "7px",
                                fontSize: "10px",
                                right: "20px",
                                fontFamily: "Montserrat, sans-serif",
                                top: "-2px",
                              }}
                            ></Box>
                          )}
                          <item.icon val={item.heading == selected ? true : false} />
                        </>
                      </Box>
                      <Box
                        sx={{
                          color: item.heading == selected ? "black" : "#1A1A1A70",
                          display: "block",
                          margin: {
                            xs: "0 12px",
                            sm: 0,
                            md: 0,
                            lg: "0 12px",
                            xl: "0 12px",
                          },
                          fontSize: {
                            xs: "14px",
                            sm: "12px",
                            md: "12px",
                            lg: "14px",
                            xl: "14px",
                          },
                          fontFamily: "Montserrat,sans-serif",
                          fontWeight: item.heading == selected ? "600" : "500",
                        }}
                        className="navbutton"
                      >
                        {item.heading}
                      </Box>
                    </Box>
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Box
          component="main"
          sx={{
            width: "100%",
            height: { xs: "100vh", md: "100vh" },
            overflowY: "auto",
          }}
        >
          <Box>
            <Toolbar className="toolbarnav" />

            {children}
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "100%",
              background: "#ffffff",
              zIndex: "99",
              justifyContent: "center",
              alignItems: "center",
              height: "30px",
            }}
          >
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

NavBar.propTypes = {
  window: PropTypes.func,
};

export default NavBar;
