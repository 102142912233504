/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

import { Box, CircularProgress, Typography } from "@mui/material";
import CalendarInfo from "./CalenderInfo";
import FilterModal from "./FilterModal";
import MapFile from "./MapFile";
import { getSx } from "./style";
import blackcrossicon from "../../../assets/images/blackcrossicon.png";
import filtericon from "../../../assets/images/filtericon.svg";
import graycalendar from "../../../assets/images/graycalendar.svg";
import handleScroll from "../../../common/handleScroll";
import NavBar from "../home";
import Favourites from "../profile/Favourites";
import noservicefound from "../../../assets/images/noservicefound.png";
import { getServiceListApi } from "../../../api/addService";
import { getServiceListEventApi } from "../../../api/eventorganiserservice";
import {
  setDays,
  setFilteredInfo,
  setLocationData,
  setSelectedService,
  showToast,
  setStartTime,
  setEndTime,
} from "../../../store/slices/common";
import { Container } from "@mui/system";
import cateringicon from "../../../assets/images/serviceimages/Eventutti  icon_Catering.svg";
import decorationicon from "../../../assets/images/serviceimages/Eventutti  icon_Decoration & Rentals.svg";
import specialicon from "../../../assets/images/serviceimages/Eventutti  icon_Special Services.svg";
import videoicon from "../../../assets/images/serviceimages/Eventutti  icon_Video & photography.svg";
import musicicon from "../../../assets/images/serviceimages/Eventutti icon_Music.svg";
import varietyicon from "../../../assets/images/serviceimages/Eventutti icon_Variety Acts.svg";
import venueicon from "../../../assets/images/serviceimages/Eventutti icon_Venues.svg";
import evlogo from "../../../assets/images/evlogo.png";
import { useHorizontalScroll } from "../../../common/HorizontalScroll";
import LocationModal from "./LocationModal";

const menuicons = {
  Venues: venueicon,
  Music: musicicon,
  Catering: cateringicon,
  "Variety Acts": varietyicon,
  "Video and Photography": videoicon,
  "Decoration and Rentals": decorationicon,
  "Special Services": specialicon,
};
const ExploreInfo = () => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState();
  const [servicesList, setServicesList] = useState([]);
  const [isMapView, setIsMapView] = useState(false);
  const [position, setPosition] = useState({});
  const [filteredList, setFilteredList] = useState([]);
  const [isStartTime, setIsStartTime] = useState(false);
  const [currentService, setCurrentService] = useState();
  const [currentLocation, setCurrentLocation] = useState({
    lat: "25.7825389",
    lng: "-80.311861",
  });
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const classes = getSx();
  const selectedValue = useSelector((state) => state.common.serviceSelected);
  const startTime = useSelector((state) => state.common.startTime);
  const endTime = useSelector((state) => state.common.endTime);
  const dispatch = useDispatch();
  const listInnerRef = useRef();
  const [page, setPage] = useState(1);
  const filteredInfo = useSelector((state) => state.common.filteredInfo);
  const days = useSelector((state) => state.common.days);
  const scrollXRef = useHorizontalScroll();
  const [locationModal, setLocationModal] = useState(true);
  const locationPopUpCalled = localStorage.getItem("locationPopUpCalled");

  useEffect(() => {
    if (selectedValue) {
      if (isApiCalled) callApiHandleList(selectedValue);
      else {
        setIsApiCalled(true);
        getCurrentLocation();
      }
    }
  }, [selectedValue?._id]);

  useEffect(() => {
    if (startTime && endTime) {
      let days1 = [];
      let date = new Date(startTime);
      const startMillis = startTime.getTime();
      const endMillis = endTime.getTime();
      const differenceMillis = Math.abs(endMillis - startMillis);
      const daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
      if (daysDifference + 1 < 7) {
        while (dayjs(date).format("MM DD YYYY") <= dayjs(endTime).format("MM DD YYYY")) {
          days1.push(date.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase());
          date.setDate(date.getDate() + 1);
        }
      } else {
        days1.push("monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday");
      }
      dispatch(setDays(days1));
      setPage(1);
      callServiceListApi(
        {
          serviceId: selectedValue?._id,
          lat: position?.lat,
          long: position?.lng,
        },
        true,
        1,
        days1,
        1000
      );
    }
  }, [startTime, endTime]);

  useEffect(() => {
    if (page != 1) {
      callServiceListApi(
        {
          serviceId: selectedValue?._id,
          lat: currentLocation?.lat,
          long: currentLocation?.lng,
        },
        true,
        page,
        days,
        1000
      );
    }
  }, [page]);

  useEffect(() => {
    if (locationPopUpCalled) {
      getCurrentLocation();
    }
  }, [locationPopUpCalled]);

  const callApi = async (lat1, long1, clearAll) => {
    setLoading(true);
    const resp = await getServiceListApi();
    setServices(resp?.data?.data);
    if (selectedValue == null) dispatch(setSelectedService(resp?.data?.data[0]));
    let val = selectedValue?._id ? selectedValue?._id : resp?.data?.data[0]?._id;
    let lat = lat1 || currentLocation?.lat;
    let long = long1 || currentLocation?.lng;

    callServiceListApi(
      { serviceId: val, lat, long, ...(filteredInfo?.city && !clearAll && { city: filteredInfo?.city }) },
      true,
      1,
      [],
      1000,
      clearAll
    );
  };

  const callServiceListApi = async (obj, isInfoRequired, pageNum, daysInfo, radius, clearAll) => {
    setLoading(true);
    let limit = isMapView ? 50 : 10;
    let resp = null;
    if (obj?.serviceId) {
      if (!isInfoRequired) {
        resp = await getServiceListEventApi(
          { ...obj, ...(clearAll ? {} : { daysFor: daysInfo }) },
          radius,
          pageNum,
          limit
        );
      } else {
        let val = {};
        if (filteredInfo?.city) {
          val = { ...val, ...(clearAll ? {} : { city: filteredInfo?.city }) };
        }
        if (filteredInfo?.category) {
          val = { ...val, categoryId: filteredInfo?.category?._id };
        }
        if (filteredInfo?.guestCount) {
          let countSplit = filteredInfo?.guestCount.split("-");
          if (countSplit.length == 1) countSplit = [500, 5000];
          val = {
            ...val,
            maxGuestCount: Number(countSplit[1]),
            minGuestCount: Number(countSplit[0]),
          };
        }
        if (filteredInfo?.budget[0] > 1 || filteredInfo?.budget[1] < 10000) {
          val = {
            ...val,
            maxPrice: filteredInfo?.budget[1],
            minPrice: filteredInfo?.budget[0],
          };
        }
        if (daysInfo?.length > 0) {
          val = { ...val, daysFor: daysInfo };
        }
        if (pageNum != 1) {
          let excludes = servicesList.map((item) => item?._id);
          val = { ...val, excludes: excludes };
        }
        resp = await getServiceListEventApi({ ...obj, ...val }, radius, pageNum, limit);
      }

      if (resp?.status == 200) {
        if (pageNum == 1) {
          setServicesList(resp?.data?.data);
          setFilteredList(resp?.data?.data);
        } else {
          setServicesList([...servicesList, ...resp?.data?.data]);
          setFilteredList([...servicesList, ...resp?.data?.data]);
        }
      }
    }
    setLoading(false);
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function getCurrentLocation(clearAll) {
    setLoading(true);
    if (navigator.geolocation) {
      const location = window.navigator && window.navigator.geolocation;
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        if (result.state === "granted") {
          if (location) {
            location.getCurrentPosition(
              (locationData) => showPosition(locationData, clearAll),
              (error) => {}
            );
          } else {
            alert("Geolocation is not supported by this browser.");
          }
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            (locationData) => showPosition(locationData, clearAll),
            errors,
            options
          );

          showPosition(
            {
              coords: { latitude: "25.7825389", longitude: "-80.311861" },
            },
            clearAll
          );
        } else if (result.state === "denied") {
          dispatch(
            showToast({
              color: "error",
              msg: "Location access denied,please reset the permission by clicking info icon",
            })
          );
          showPosition(
            {
              coords: { latitude: "25.7825389", longitude: "-80.311861" },
            },
            clearAll
          );
          callDeniedServiceListApi();
        }
      });
    }
  }

  const callDeniedServiceListApi = async () => {
    const resp = await getServiceListApi();
    setServices(resp?.data?.data);
  };

  function showPosition(position, clearAll) {
    if (filteredInfo?.lat && !clearAll) {
      setCurrentLocation({ lat: filteredInfo?.lat, lng: filteredInfo?.lng });
      setIsApiCalled(true);
      callApi(filteredInfo?.lat, filteredInfo?.lng);
    } else {
      let lat = position.coords.latitude;
      let long = position.coords.longitude;
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      dispatch(setLocationData({ lat: lat, long: long }));

      callApi(lat, long, clearAll);

      setFilteredInfo({
        serviceId: filteredInfo?.serviceId,
        lat,
        long,
        radius: 1000,
        page: 1,
        limit: 10,
      });
    }
  }

  function errors(err) {
    dispatch(showToast({ color: "error", msg: err.message }));
  }

  const filterServiceList = (lat, long) => {
    setLoading(true);
    const resp = servicesList.filter((item) => {
      let latVal = item?.isAccepted ? Number(item?.location?.lat) : Number(item?.location?.newLat);
      let longVal = item?.isAccepted ? Number(item?.location?.long) : Number(item?.location?.newLong);
      if (latVal == lat && longVal == long) return item;
    });
    setFilteredList(resp);
    setLoading(false);
  };

  const handleSetPage = () => {
    setPage(servicesList?.length / 10 + 1);
  };

  const callApiHandleList = (item) => {
    let info = false;
    let radius = !isMapView ? 1000 : 50;
    if (filteredInfo?.lat) info = true;

    callServiceListApi(
      {
        serviceId: item?._id,
        lat: currentLocation?.lat,
        long: currentLocation?.lng,
      },
      info,
      1,
      days,
      radius
    );
  };

  return (
    <>
      <Container
        sx={{
          padding: {
            xs: "0 15px",
            sm: "0 30px",
            md: "0 30px",
            lg: "0 30px",
            xl: "0 30px",
          },
        }}
        maxWidth="xl"
      >
        <Box sx={classes.main}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: { xs: "wrap", md: "nowrap" },
              marginBottom: {
                xs: "10px",
                sm: "10px",
                md: "10px",
                lg: "15px",
                xl: "24px",
              },
            }}
          >
            <Box
              sx={{
                fontSize: {
                  xs: "20px",
                  sm: "22px",
                  md: "24px",
                  lg: "25px",
                  xl: "25px",
                },
                color: "#111111",
                fontWeight: 700,
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              Explore
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }} className="exploredatefiltebox">
              <Box
                sx={{
                  backgroundColor: "rgba(246, 245, 245, 1)",
                  padding: {
                    xs: "0 10px",
                    sm: "0 20px",
                    md: "0 20px",
                    lg: "0 20px",
                    xl: "5px 20px",
                  },
                  borderRadius: "33px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: {
                    xs: 0,
                    sm: "10px",
                    md: "10px",
                    lg: "10px",
                    xl: "10px",
                  },
                  marginRight: {
                    xs: "5px",
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  fontSize: { xs: "10px", md: "13px", lg: "14px", xl: "15px" },
                  height: {
                    xs: "40px",
                    sm: "40px",
                    md: "40px",
                    lg: "40px",
                    xl: "48px",
                  },
                  fontFamily: "Montserrat, sans-serif",
                }}
                onClick={() => {
                  setIsStartTime(true);
                  setOpenCalendar(true);
                }}
              >
                <img alt="" src={graycalendar} style={{ marginRight: "6px" }} />{" "}
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.3)",
                    marginRight: "6px",
                    fontSize: { xs: "10px", md: "13px" },
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  Start Date
                </span>
                {startTime && (
                  <span style={{ color: "black", fontWeight: "bold" }} className="exploretime">
                    {dayjs(startTime?.toDateString()).format("DD MMM YYYY")}
                  </span>
                )}
              </Box>
              <Box
                sx={{
                  backgroundColor: "rgba(246, 245, 245, 1)",
                  padding: {
                    xs: "0 10px",
                    sm: "0 20px",
                    md: "0 20px",
                    lg: "0 20px",
                    xl: "0 20px",
                  },
                  borderRadius: "33px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: {
                    xs: 0,
                    sm: "10px",
                    md: "10px",
                    lg: "10px",
                    xl: "10px",
                  },
                  marginRight: {
                    xs: "5px",
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  fontSize: { xs: "10px", md: "13px", lg: "14px", xl: "15px" },
                  height: {
                    xs: "40px",
                    sm: "40px",
                    md: "40px",
                    lg: "40px",
                    xl: "48px",
                  },
                  fontFamily: "Montserrat, sans-serif",
                }}
                onClick={() => {
                  setIsStartTime(false);
                  if (startTime) setOpenCalendar(true);
                }}
              >
                <img alt="" src={graycalendar} style={{ marginRight: "6px" }} />{" "}
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.3)",
                    marginRight: "6px",
                    fontSize: { xs: "10px", md: "13px" },
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  End Date
                </span>
                {/* <span style={{ color: "black", fontWeight: "bold" }} className="exploretime"> */}
                {endTime && (
                  <span style={{ color: "black", fontWeight: "bold" }} className="exploretime">
                    <>{dayjs(endTime?.toDateString()).format("DD MMM YYYY")}</>
                  </span>
                )}
                {/* </span> */}
              </Box>
              {endTime && (
                <img
                  alt=""
                  src={blackcrossicon}
                  style={{
                    width: "20px",
                    height: "20px",
                    margin: "auto",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    dispatch(setEndTime());
                    dispatch(setStartTime());
                    setPage(1);
                    dispatch(setDays([]));
                    callServiceListApi(
                      {
                        serviceId: selectedValue?._id,
                        lat: currentLocation?.lat,
                        long: currentLocation?.lng,
                      },
                      true,
                      1,
                      [],
                      1000
                    );
                  }}
                />
              )}
              {/* <Box
                            sx={{
                                cursor: loading ? "not-allowed" : "pointer", marginLeft: {
                                    xs: 0, sm: "10px", md: "10px", lg: "10px", xl: "10px"
                                }, display: 'flex', alignItems: 'center', marginRight: {
                                    xs: '5px', sm: 0, md: 0, lg: 0, xl: 0
                                }
                            }}
                            onClick={() => {
                                if (!loading) {
                                    saveServiceLocations();
                                    let view = !isMapView;
                                    setIsMapView((prev) => !prev);
                                    let radius = !view ? 1000 : 50;
                                    callServiceListApi({
                                        serviceId: selectedValue?._id,
                                        lat: currentLocation?.lat,
                                        long: currentLocation?.lng,
                                    }, true, 1, [], radius);
                                }


                            }}
                        >
                            <img alt="" src={!isMapView ? viewmapicon : viewlisticon} className="image-size-adjust" />
                        </Box> */}
              <Box
                sx={{
                  marginLeft: {
                    xs: 0,
                    sm: "10px",
                    md: "10px",
                    lg: "10px",
                    xl: "10px",
                  },
                  display: "flex",
                  alignItems: "center",
                  marginRight: {
                    xs: "5px",
                    sm: 0,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenFilterModal(true);
                }}
              >
                <img alt="" src={filtericon} className="image-size-adjust" />
              </Box>
            </Box>
          </Box>
          <Box sx={classes.menus} ref={scrollXRef}>
            {services?.map((item, index) => {
              return (
                <Box
                  key={item?._id}
                  onClick={() => {
                    dispatch(setSelectedService(item));
                    setCurrentService();
                    dispatch(
                      setFilteredInfo({
                        ...filteredInfo,
                        category: "",
                      })
                    );
                    setPage(1);
                  }}
                  sx={
                    selectedValue?.name != item?.name
                      ? { ...classes.singlemenu }
                      : {
                          ...classes.singlemenu,
                          background: "linear-gradient(90deg, #17BAAE 0%, #29EAA5 100.22%)",
                          color: "#fff",
                        }
                  }
                >
                  <img
                    src={menuicons[item?.name] || evlogo}
                    style={{ width: "25px", height: "25px", marginRight: "5px" }}
                  />
                  {selectedValue?.name == item?.name ? item.selComp : item.component}
                  <Typography
                    sx={{
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {!isMapView ? (
            loading && page == 1 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <CircularProgress sx={{ height: "30vh" }} />
              </Box>
            ) : servicesList.length == 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  flexDirection: "column",
                }}
              >
                <img alt="" src={noservicefound} style={{ width: "185px", height: "185px", padding: "10px" }} />
                <Typography
                  sx={{ fontFamily: "Montserrat, sans-serif" }}
                  variant="h5"
                  fontWeight="bold"
                  margin="10px 0px"
                >
                  No Service found
                </Typography>
                <Typography
                  sx={{ fontFamily: "Montserrat, sans-serif" }}
                  variant="h6"
                  color="#A7A7A7"
                  margin="10px 0px"
                  textAlign="center"
                  fontSize="20px"
                >
                  Currently, no Eventutti partners are registered for this category—stay tuned as we grow our community
                  and bring you exciting new options!
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    overflow: "auto",
                    height: {
                      xs: "calc(100vh - 244px)",
                      sm: "calc(100vh - 250px)",
                      md: "calc(100vh - 260px)",
                      lg: "calc(100vh - 237px)",
                      xl: "calc(100vh - 304px)",
                    },
                    paddingBottom: "30px",
                    flexWrap: "wrap",
                    marginLeft: {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: "-12px",
                      xl: "-12px",
                    },
                    marginRight: {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: "-12px",
                      xl: "-12px",
                    },
                  }}
                  className="explore-list"
                  ref={listInnerRef}
                  onScroll={() => {
                    handleScroll(listInnerRef, servicesList?.length, handleSetPage);
                  }}
                >
                  {servicesList?.map((item) => {
                    return <Favourites item={item} isExplore={true} fromSettings={false} />;
                  })}
                </Box>
                {loading && page > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </>
            )
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                height: "auto",
                overflow: "auto",
                paddingBottom: 0,
                flexDirection: { xs: "column-reverse", md: "row" },
                position: "relative",
                flexWrap: {
                  xs: "wrap",
                  sm: "wrap",
                  md: "inherit",
                  lg: "wrap",
                  xl: "wrap",
                },
                marginLeft: {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: "-12px",
                  xl: "-12px",
                },
                marginRight: {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: "-12px",
                  xl: "-12px",
                },
              }}
            >
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: { md: "2", lg: 2, xl: 1.4 },
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : filteredList?.length == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    flex: { md: "2", lg: 2, xl: 1.4 },
                    height: { xs: "33%", sm: "36%", md: "100%" },
                    width: { xs: "100%", md: "70%" },
                    margin: "auto",
                  }}
                  className="noservicenone"
                >
                  <img alt="" src={noservicefound} style={{ width: "145px", height: "145px" }} />
                  <Typography
                    sx={{ fontFamily: "Montserrat, sans-serif" }}
                    variant="h5"
                    fontWeight="bold"
                    margin="10px 0px"
                  >
                    No Service found
                  </Typography>
                  <Typography
                    variant="h6"
                    color="rgba(0, 0, 0, 0.5)"
                    margin="10px 0px"
                    width="75%"
                    sx={{ fontSize: { xs: "15px", xl: "20px", fontFamily: "Montserrat, sans-serif" } }}
                  >
                    Currently, no Eventutti partners are registered for this category—stay tuned as we grow our
                    community and bring you exciting new options!
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "440px",
                      lg: "440px",
                      xl: "550px",
                    },
                    height: {
                      xs: "calc(100vh - 244px)",
                      sm: "calc(100vh - 560px)",
                      md: "calc(100vh - 260px)",
                      lg: "calc(100vh - 237px)",
                      xl: "calc(100vh - 304px)",
                      xxl: "calc(100vh - 304px)",
                    },
                    overflow: "auto",
                    paddingBottom: 0,
                    flexDirection: { xs: "column-reverse", md: "row" },
                    flexWrap: "wrap",
                    position: "relative",
                  }}
                  onScroll={() => {
                    handleScroll(listInnerRef, filteredList?.length, handleSetPage);
                  }}
                  ref={listInnerRef}
                >
                  {" "}
                  {filteredList?.map((item) => {
                    return <Favourites item={item} isExplore={true} inMap={true} key={item?._id} />;
                  })}
                </Box>
              )}
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "calc(100% - 400px)",
                    lg: "calc(100% - 440px)",
                    xl: "calc(100% - 550px)",
                  },
                  height: {
                    xs: "auto",
                    sm: "auto",
                    md: "calc(100vh - 260px)",
                    lg: "calc(100vh - 237px)",
                    xl: "calc(100vh - 304px)",
                    xxl: "calc(100vh - 304px)",
                  },
                  padding: {
                    xs: "10px 5px 10px 15px",
                    sm: "15px",
                    md: "10px 5px 10px 15px",
                    lg: "10px 5px 0 15px",
                    xl: "10px 5px 0 15px",
                  },
                  overflow: "auto",
                  marginBottom: {
                    xs: "15px",
                    sm: "30px",
                    md: "0",
                    lg: 0,
                    xl: 0,
                  },
                }}
              >
                <MapFile
                  currentService={currentService}
                  filteredList={filteredList}
                  setCurrentService={setCurrentService}
                  callServiceListApi={callServiceListApi}
                  selectedValue={selectedValue}
                  filterServiceList={filterServiceList}
                  servicesList={servicesList}
                  currentLocation={currentLocation}
                  setCurrentLocation={setCurrentLocation}
                  days={days}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Container>

      {openCalendar && (
        <CalendarInfo
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          endTime={endTime}
          isStartTime={isStartTime}
          startTime={startTime}
        />
      )}
      {openFilterModal && (
        <FilterModal
          openFilterModal={openFilterModal}
          setOpenFilterModal={setOpenFilterModal}
          currentLocation={currentLocation}
          selectedValue={selectedValue}
          callServiceListApi={callServiceListApi}
          setCurrentLocation={setCurrentLocation}
          setPage={setPage}
          isMapView={isMapView}
          position={position}
          getCurrentLocation={getCurrentLocation}
        />
      )}

      {locationModal && !locationPopUpCalled && (
        <LocationModal
          locationModal={locationModal}
          handleClose={() => {
            setLocationModal(false);
            callApi({ lat: "25.7825389", lng: "-80.311861" });
            setIsApiCalled(true);
          }}
          handleOk={() => {
            setLocationModal(false);
            localStorage.setItem("locationPopUpCalled", true);
            setIsApiCalled(true);
          }}
        />
      )}
    </>
  );
};
const Explore = () => {
  return (
    <NavBar>
      <ExploreInfo />
    </NavBar>
  );
};

export default Explore;
