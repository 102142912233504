import React from "react";
import { Checkbox, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import notification2 from "../../../assets/images/svg/profileicons/notification2.svg";
import CustomSwitch from "../../../component/Switch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import uncheckedicon from "../../../assets/images/uncheckboxicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setProfileInfo } from "../../../store/slices/profileinfo";
import { updateProfileApi } from "../../../api/profile";

const ManageNotifications = () => {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");

  const [loading, setLoading] = React.useState(false);

  const profileInfo = useSelector((state) => state.profile.profileInfo);

  const updateNotifications = async (key, type) => {
    setLoading(true);
    const payload = {
      preferences: {
        ...profileInfo.preferences,
        [key]: {
          ...profileInfo.preferences[key],
          [type]: !profileInfo.preferences[key][type],
        },
      },
    };
    const response = await updateProfileApi(payload);
    if (response.status === 200) {
      dispatch(setProfileInfo({ ...profileInfo, preferences: payload?.preferences }));
    }

    setLoading(false);
  };

  const toggleNotifications = async () => {
    setLoading(true);

    const payload = {
      isNotificationEnabled: !profileInfo.isNotificationEnabled,
    };
    const response = await updateProfileApi(payload);
    if (response.status === 200) {
      dispatch(setProfileInfo({ ...profileInfo, isNotificationEnabled: payload?.isNotificationEnabled }));
    }

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(255, 255, 255, 0.5)",
            zIndex: 1000,
          }}
        >
          <CircularProgress size={40} style={{ color: "#17baae" }} />
        </Box>
      )}
      <Typography
        variant="h5"
        sx={{
          fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "24px", xl: "28px" },
          lineHeight: { xs: "26px", sm: "28px", md: "32px", lg: "36px", xl: "40px" },
          fontWeight: 700,
          marginBottom: { xs: "15px", sm: "15px", md: "15px", lg: "20px", xl: "30px" },
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        Manage Notifications
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "18px",
          border: " 1px solid #DADADA",
          borderRadius: "18px",
          marginBottom: "24px",
        }}
      >
        <img src={notification2} alt="" />
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "22px", md: "24px", lg: "26px", xl: "28px" },
            fontWeight: "700",
            color: "#111111",
            marginLeft: "1rem",
          }}
        >
          Notifications
        </Typography>
        <Box sx={{ width: "fit-content", marginLeft: "auto" }}>
          <CustomSwitch checked={profileInfo?.isNotificationEnabled} onChange={toggleNotifications} />
        </Box>
      </Box>
      <Box sx={{ display: profileInfo?.isNotificationEnabled ? "block" : "none" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#F5F5F5",
            padding: "16px",
            borderRadius: "16px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px", lg: "18px" },
                fontWeight: "600",
                width: "40%",
              }}
            >
              Notification Preferences
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px", lg: "18px" },
                fontWeight: "600",
                width: "20%",
                textAlign: "center",
                paddingLeft: "12px",
              }}
            >
              In-App
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "14px", md: "16px", lg: "18px" },
                fontWeight: "600",
                width: "20%",
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Push Notification
            </Typography>
            {role === "1" && (
              <Typography
                sx={{
                  fontSize: { xs: "14px", md: "16px", lg: "18px" },
                  fontWeight: "600",
                  width: "20%",
                  textAlign: "center",
                  paddingRight: "12px",
                }}
              >
                Email
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: "1px solid #DADADA",
              background: "#FFFFFF",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Booking Status Notifications
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  opacity: "0.5",
                }}
              >
                Send updates related to Booking Status
              </Typography>
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.bookingStatus?.inApp}
                onChange={() => updateNotifications("bookingStatus", "inApp")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.bookingStatus?.push}
                onChange={() => updateNotifications("bookingStatus", "push")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            {role === "1" && (
              <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Checkbox
                  sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                  icon={<img src={uncheckedicon} />}
                  checked={profileInfo?.preferences?.bookingStatus?.email}
                  onChange={() => updateNotifications("bookingStatus", "email")}
                  checkedIcon={
                    <CheckCircleIcon
                      sx={{
                        color: "rgb(22, 192, 152)",
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: "1px solid #DADADA",
              background: "#FFFFFF",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Booking Request Notifications
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  opacity: "0.5",
                }}
              >
                Send updates related to Booking Requests
              </Typography>
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.bookingRequest?.inApp}
                onChange={() => updateNotifications("bookingRequest", "inApp")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.bookingRequest?.push}
                onChange={() => updateNotifications("bookingRequest", "push")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            {role === "1" && (
              <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }} />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: "1px solid #DADADA",
              background: "#FFFFFF",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Messages Notifications
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  opacity: "0.5",
                }}
              >
                Send updates whenever I receive a message
              </Typography>
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.message?.inApp}
                onChange={() => updateNotifications("message", "inApp")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.message?.push}
                onChange={() => updateNotifications("message", "push")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            {role === "1" && (
              <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }} />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px",
              border: "1px solid #DADADA",
              background: "#FFFFFF",
              borderRadius: "12px",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Reviews & Ratings Notifications
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  opacity: "0.5",
                }}
              >
                Send updates whenever I receive a rating or a review
              </Typography>
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.reviewAndRating?.inApp}
                onChange={() => updateNotifications("reviewAndRating", "inApp")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                sx={{ color: "rgba(23, 186, 174, 1)", borderRadius: "50%" }}
                icon={<img src={uncheckedicon} />}
                checked={profileInfo?.preferences?.reviewAndRating?.push}
                onChange={() => updateNotifications("reviewAndRating", "push")}
                checkedIcon={
                  <CheckCircleIcon
                    sx={{
                      color: "rgb(22, 192, 152)",
                    }}
                  />
                }
              />
            </Box>
            {role === "1" && (
              <Box sx={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ManageNotifications;
