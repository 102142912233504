import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const CSwitch = styled(Switch)(({ theme }) => ({
  height: 40,
  switchBase: {
    padding: 11,
  },
  "& .MuiSwitch-switchBase": {
    color: "black",
    fontFamily: "Montserrat, sans-serif",
    marginBottom: 0,
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: " rgba(23, 186, 174, 1) !important",
    fontFamily: "Montserrat, sans-serif",
    marginBottom: 0,
    "&:hover": {
      backgroundColor: "linear-gradient(to right, rgba(23, 186, 174, 1), rgba(41, 234, 165, 1))",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgba(23, 186, 174, 1)",
    color: "linear-gradient(to right, rgba(23, 186, 174, 0.1), rgba(41, 234, 165, 0.1))",
  },
}));

export default function CustomSwitch({ label, checked, onChange, name }) {
  return <CSwitch {...label} checked={checked} onChange={onChange} name={name} />;
}

CustomSwitch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
