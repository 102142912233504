import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import ButtonComp from "../../../component/Button";
import CustomTextField from "../../../component/Textfield";
import { CircularProgress } from "@material-ui/core";
import { Box, Typography, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { getSx } from "./style";
import editicon from "../../../assets/images/svg/editicon.svg";
import "react-phone-input-2/lib/style.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { getProfileApi, updateProfileApi } from "../../../api/profile";
import { uploadImageApi } from "../../../api/Signup";
import { showToast } from "../../../store/slices/common";
import { setProfileInfo } from "../../../store/slices/profileinfo";

const ProfileSettings = () => {
  const classes = getSx();
  const dispatch = useDispatch();
  const datePickerRef = useRef(null);
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Required")
      .matches(/^[A-Za-záéíóúñü¡¿ ]+$/, "Invalid firstName")
      .max(50, "First name must be less than 50 characters"),
    lastName: Yup.string()
      .required("Required")
      .matches(/^[A-Za-záéíóúñü¡¿ ]+$/, "Invalid lastName")
      .max(50, "Last name must be less than 50 characters"),
  });

  const [image, setImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({});
  const [oldImage, setOldImage] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const profileInfo = useSelector((state) => state.profile.profileInfo);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    formik.setFieldValue("DOB", dayjs(date).format("MM/DD/YYYY"));
    setOpen(false);
  };

  const Circle = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  }));
  const handleFileChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(file);
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0]) {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        const response = await uploadImageApi(formData);
        if (response.type === "success") {
          setImage({ url: response?.data?.fileUrl, key: response?.data?.key });
          setOldImage(response?.data?.fileUrl);
        }
      }
    }
    setLoading(false);
  };
  const callUpdateProfileApi = async () => {
    setLoad(true);
    try {
      const resp = await updateProfileApi({ ...formik.values, profileImage: { ...image } });
      if (resp.status === 200) {
        setData({ ...formik.values });
        dispatch(showToast({ msg: "Profile Updated Sucessfully", type: "success" }));
      }
      setLoad(false);
    } catch (err) {
      setLoad(false);
    }
  };
  const callApi = async () => {
    setLoad(true);
    const response = await getProfileApi();
    if (response?.status == 200) {
      setImage(response?.data?.data?.profileImage);
      formik.setValues({
        firstName: response?.data?.data?.firstName,
        lastName: response?.data?.data?.lastName,
        email: response?.data?.data?.email,
        countryCode: response?.data?.data?.countryCode,
        phoneNumber: response?.data?.data?.phoneNumber,
        DOB: response?.data?.data?.DOB,
      });
      setData({
        firstName: response?.data?.data?.firstName,
        lastName: response?.data?.data?.lastName,
        countryCode: response?.data?.data?.countryCode,
        phoneNumber: response?.data?.data?.phoneNumber,
        DOB: response?.data?.data?.DOB,
      });
      setSelectedDate(response?.data?.data?.DOB);

      dispatch(
        setProfileInfo({
          ...profileInfo,
          preferences: response?.data?.data?.preferences,
          isNotificationEnabled: response?.data?.data?.isNotificationEnabled,
        })
      );
    }
    setLoad(false);
  };

  const formik = useFormik({
    initialValues: { email: "", firstName: "", lastName: "", countryCode: "", phoneNumber: "" },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (token) callApi();
  }, []);
  return load ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box sx={classes.infocontainer}>
        <Typography
          sx={{
            fontSize: {
              xs: "20px",
              sm: "22px",
              md: "24px",
              lg: "25px",
              xl: "25px",
            },
            color: "#111111",
            fontWeight: 700,
            marginBottom: {
              xs: "10px",
              sm: "10px",
              md: "10px",
              lg: "15px",
              xl: "24px",
            },
            fontFamily: "Montserrat, sans-serif",
          }}
          variant="h5"
        >
          Edit Profile
        </Typography>
        <Typography
          sx={{
            marginBottom: "10px",
            fontWeight: 600,
            fontFamily: "Montserrat, sans-serif",
            fontSize: {
              xs: "15px",
              sm: "16px",
              md: "16px",
              lg: "18px",
              xl: "18px",
            },
          }}
        >
          User Profile
        </Typography>
        <Box
          sx={{
            height: {
              xs: "calc(75vh - 100px)",
              sm: "auto",
              md: "auto",
              lg: "auto",
            },
            overflow: "auto",
          }}
        >
          <Box sx={classes.profileimg1}>
            {loading ? (
              <Box sx={classes.profileImgbox}>
                <CircularProgress color="rgba(23, 186, 174, 1)" />
              </Box>
            ) : (
              <Box sx={{ position: "relative" }}>
                <Circle sx={{ width: "117px", height: "117px" }}>
                  <img
                    alt={""}
                    src={image.url}
                    style={{ width: "100%", height: "100%", borderRadius: "50%", objectFit: "cover" }}
                  />
                </Circle>
                <Box sx={classes.plusicon}>
                  <input
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  <label htmlFor="file-upload">
                    <Box>
                      <img src={editicon} style={{ cursor: "pointer" }} />
                    </Box>
                  </label>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              ...classes.subform,
              marginTop: {
                xs: "10px",
                sm: 0,
                md: 0,
                lg: 0,
                xl: 0,
              },
            }}
          >
            <Box sx={classes.subform1}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "15px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
              >
                <Typography sx={classes.smallheading}>First Name</Typography>
                <CustomTextField
                  type="text"
                  variant="outlined"
                  placeholder="Enter First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={(e) => {
                    formik.setFieldTouched("firstName", true);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  errorMessage={formik.touched.firstName && formik.errors.firstName}
                />
              </Box>
            </Box>
            <Box sx={classes.subform2}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "15px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
              >
                <Typography sx={classes.smallheading}>Last Name</Typography>
                <CustomTextField
                  type="text"
                  variant="outlined"
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={(e) => {
                    formik.setFieldTouched("lastName", true);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  errorMessage={formik.touched.lastName && formik.errors.lastName}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={classes.subform}>
            <Box sx={classes.subform1}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "15px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
              >
                <Typography sx={classes.smallheading}>Email Address</Typography>
                <CustomTextField
                  type="text"
                  variant="outlined"
                  placeholder="abc@yopmail.com"
                  disabled={true}
                  value={formik.values.email}
                />
              </Box>
            </Box>
            <Box sx={classes.subform2}>
              <Box
                sx={{
                  marginBottom: {
                    xs: "15px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
                className="flag-ui-box"
              >
                <Typography sx={classes.smallheading}>Phone Number</Typography>
                <PhoneInput
                  country={"us"}
                  sx={classes.phoneinput}
                  value={`${formik.values.countryCode}+${formik.values.phoneNumber}`}
                  onChange={(value, country) => {
                    formik.setFieldValue("countryCode", `+${country?.dialCode}`);
                    formik.setFieldValue("phoneNumber", value.slice(country?.dialCode?.length));
                  }}
                  inputStyle={{
                    ...classes.inputStyle,
                  }}
                  containerStyle={classes.containerStyle}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={classes.subform}>
            <Box sx={classes.subform1}>
              <Box sx={{ margin: "20px 0px" }}>
                <Typography sx={classes.smallheading}>Date of Birth</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={selectedDate ? dayjs(selectedDate) : null}
                    onChange={handleDateChange}
                    ref={datePickerRef}
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                    onClick={() => {
                      setOpen(true);
                    }}
                    format="MM/DD/YYYY"
                    sx={{
                      width: "100%",
                      borderRadius: "18px",
                      height: "60px",
                      "& fieldset": {
                        border: "1px solid #EBEBEB",
                        height: "60px",
                        borderRadius: "18px",
                      },
                      "& fieldset:hover": {
                        border: "1px solid #EBEBEB",
                        height: "60px",
                        borderRadius: "18px",
                      },
                      "& fieldset:focus": {
                        border: "1px solid #EBEBEB",
                        height: "60px",
                        borderRadius: "18px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EBEBEB !important",
                      },
                      "& input": {
                        fontWeight: 600,
                      },
                    }}
                    maxDate={dayjs().endOf("day")}
                    slotProps={{
                      textField: {
                        onClick: () => setOpen(true),
                        readOnly: true,
                      },
                    }}
                    renderInput={(props) => (
                      <TextField
                        inputProps={{ readOnly: true }}
                        onClick={() => setOpen(true)}
                        onKeyDown={(e) => e.preventDefault()}
                        {...props}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.savebtn}>
          <ButtonComp
            label="Save"
            style={{
              fontFamily: "Montserrat,sans-serif",
              height: "60px",
              width: "100%",
              fontWeight: 600,
              fontSize: "20px",
            }}
            onClick={() => {
              callUpdateProfileApi();
            }}
            disabled={
              !(
                formik.isValid &&
                formik.dirty &&
                image &&
                formik.values.countryCode &&
                formik.values.DOB &&
                formik.values.phoneNumber &&
                !(
                  data.firstName == formik.values.firstName &&
                  data.lastName == formik.values.lastName &&
                  data.phoneNumber == formik.values.phoneNumber &&
                  data.DOB == formik.values.DOB &&
                  oldImage == image.fileUrl
                )
              )
            }
            type={
              formik.isValid &&
              formik.dirty &&
              formik.values.countryCode &&
              formik.values.phoneNumber &&
              image &&
              formik.values.DOB &&
              !(
                data.firstName == formik.values.firstName &&
                data.lastName == formik.values.lastName &&
                data.phoneNumber == formik.values.phoneNumber &&
                data.DOB == formik.values.DOB &&
                oldImage == image.fileUrl
              )
                ? "active"
                : "disabled"
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default ProfileSettings;
